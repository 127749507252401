import PexesoButtonHintDisabled from '../../assets/images/button_hint_disabled.png'
import PexesoButtonHintEnabled from '../../assets/images/button_hint_enabled.png'
import FirstPlace from '../../assets/images/classrooms/activities/1st_place.webp'
import SecondPlace from '../../assets/images/classrooms/activities/2nd_place.webp'
import ThirdPlace from '../../assets/images/classrooms/activities/3rd_place.webp'
import ConnectionCorner from '../../assets/images/classrooms/activities/connection_corner.webp'
import FlashingLights from '../../assets/images/classrooms/activities/flashing_lights.webp'
import FlipcardsCard from '../../assets/images/classrooms/activities/flipcards_card.webp'
import FlipcardsCorner from '../../assets/images/classrooms/activities/flipcards_corner.webp'
import FlipcardsPreview from '../../assets/images/classrooms/activities/flipcards_preview_image.webp'
import HomeworkCard from '../../assets/images/classrooms/activities/homework_card.webp'
import HomeworkCorner from '../../assets/images/classrooms/activities/homework_corner.webp'
import HomeworkIcon from '../../assets/images/classrooms/activities/homework_icon.webp'
import HomeworkPreview from '../../assets/images/classrooms/activities/homework_preview_image.webp'
import PexesoGameCard from '../../assets/images/classrooms/activities/pexeso_card.webp'
import PexesoCorner from '../../assets/images/classrooms/activities/pexeso_corner.webp'
import PexesoIcon from '../../assets/images/classrooms/activities/pexeso_icon.webp'
import PexesoPreview from '../../assets/images/classrooms/activities/pexeso_preview_image.webp'
import QuizCard from '../../assets/images/classrooms/activities/quiz_card.webp'
import QuizCorner from '../../assets/images/classrooms/activities/quiz_corner.webp'
import QuizIcon from '../../assets/images/classrooms/activities/quiz_icon.webp'
import QuizPreview from '../../assets/images/classrooms/activities/quiz_preview_image.webp'
import SelfLearningIcon from '../../assets/images/classrooms/activities/self_learning_icon.webp'
import SelfStudyPreview from '../../assets/images/classrooms/activities/self_learning_preview_image.webp'
import SelfStudyCard from '../../assets/images/classrooms/activities/self_study_card.webp'
import SelfStudyCorner from '../../assets/images/classrooms/activities/self_study_corner.webp'
import Star from '../../assets/images/classrooms/activities/stars.webp'
import TeamLeaderboardBg from '../../assets/images/classrooms/activities/team_leaderboard_bg.webp'
import TestCard from '../../assets/images/classrooms/activities/test_card.webp'
import TestCorner from '../../assets/images/classrooms/activities/test_corner.webp'
import TestIcon from '../../assets/images/classrooms/activities/test_icon.webp'
import TestPreview from '../../assets/images/classrooms/activities/test_preview_image.webp'
import WheelCard from '../../assets/images/classrooms/activities/wheel_card.webp'
import WheelPreview from '../../assets/images/classrooms/activities/wheel_preview_image.webp'
import ActivityStar from '../../assets/images/classrooms/activity_star.webp'
import ClassroomHeaderSwitch from '../../assets/images/classrooms/classroom_header_switch.svg'
import Stars from '../../assets/images/classrooms/classroom_stars.webp'
import CornerBackground from '../../assets/images/classrooms/corner_background.webp'
import CorrectTick from '../../assets/images/classrooms/correct_tick.svg'
import EnglandCrown from '../../assets/images/classrooms/england_crown.webp'
import BooksIcon from '../../assets/images/classrooms/progress/books.webp'
import MicrophoneIcon from '../../assets/images/classrooms/progress/microphone.webp'
import PencilIcon from '../../assets/images/classrooms/progress/pencil.webp'
import SchoolBag from '../../assets/images/classrooms/progress/school_bag.webp'
import SpeakerIcon from '../../assets/images/classrooms/progress/speaker.webp'
import Test from '../../assets/images/classrooms/progress/test.webp'
import PhoneBooth from '../../assets/images/classrooms/subject/english/phone_booth.webp'
import SchoolBigBen from '../../assets/images/classrooms/subject/english/school_big_ben.webp'
import SchoolBus from '../../assets/images/classrooms/subject/english/school_bus.webp'
import SchoolDinosaur from '../../assets/images/classrooms/subject/english/school_dinosaur.webp'
import ClassBakery from '../../assets/images/classrooms/subject/french/bakery.webp'
import ClassEiffelTower from '../../assets/images/classrooms/subject/french/eiffel_tower.webp'
import ClassMonaLisa from '../../assets/images/classrooms/subject/french/mona_lisa.webp'
import ClassNotreDame from '../../assets/images/classrooms/subject/french/notre_dame.webp'
import ClassTourDeFrance from '../../assets/images/classrooms/subject/french/tour_de_france.webp'
import ClassGerman1 from '../../assets/images/classrooms/subject/german/german_1.webp'
import ClassGerman2 from '../../assets/images/classrooms/subject/german/german_2.webp'
import ClassGerman3 from '../../assets/images/classrooms/subject/german/german_3.webp'
import ClassGerman4 from '../../assets/images/classrooms/subject/german/german_4.webp'
import ClassGerman5 from '../../assets/images/classrooms/subject/german/german_5.webp'
import ClassSpanish1 from '../../assets/images/classrooms/subject/spanish/spanish_1.webp'
import ClassSpanish2 from '../../assets/images/classrooms/subject/spanish/spanish_2.webp'
import ClassSpanish3 from '../../assets/images/classrooms/subject/spanish/spanish_3.webp'
import ClassSpanish4 from '../../assets/images/classrooms/subject/spanish/spanish_4.webp'
import ClassSpanish5 from '../../assets/images/classrooms/subject/spanish/spanish_5.webp'
import EducationLevelA1 from '../../assets/images/dictionary/education_a1.webp'
import EducationLevelA2 from '../../assets/images/dictionary/education_a2.webp'
import EducationLevelB2 from '../../assets/images/dictionary/education_b2.webp'
import EducationLevelC1 from '../../assets/images/dictionary/education_c1.webp'
import EducationLevelC2 from '../../assets/images/dictionary/education_c2.webp'
import NoResultDictionary from '../../assets/images/dictionary/search_not_found.webp'
import BatSteal from '../../assets/images/flipcards/bat_steal.webp'
import BatSuperSteal from '../../assets/images/flipcards/bat_super_steal.webp'
import CorrectAnswerTick from '../../assets/images/flipcards/correct_answer_tick.svg'
import Crown from '../../assets/images/flipcards/crown.webp'
import DoubleHoney from '../../assets/images/flipcards/double_honey.webp'
import ExclamationMark from '../../assets/images/flipcards/exclamation_mark.svg'
import ExclamationMarkDisabled from '../../assets/images/flipcards/exclamation_mark_disabled.svg'
import FlipcardsLogo from '../../assets/images/flipcards/flipcards_logo.svg'
import Hamburger from '../../assets/images/flipcards/hamburger.svg'
import HedgeTrade from '../../assets/images/flipcards/hedge_trade.webp'
import IncorrectAnswer from '../../assets/images/flipcards/incorrect_mark.svg'
import LeadMouse from '../../assets/images/flipcards/lead_mouse.webp'
import PawPattern from '../../assets/images/flipcards/paw_pattern.webp'
import Rabbit from '../../assets/images/flipcards/rabbit.webp'
import RandomCube from '../../assets/images/flipcards/random_cube.svg'
import TeddyBear from '../../assets/images/flipcards/teddy_bear.webp'
import Thanksgiving from '../../assets/images/flipcards/thanksgiving.webp'
import TreeThread from '../../assets/images/flipcards/tree_thread.webp'
import UnluckyWolf from '../../assets/images/flipcards/unlucky_wolf.webp'
import Logo from '../../assets/images/foxino_beta.svg'
import WrongRights from '../../assets/images/insufficient_roles.webp'
import PexesoAnimalsBear from '../../assets/images/pexeso/animals/bear.webp'
import PexesoAnimalsCat from '../../assets/images/pexeso/animals/cat.webp'
import PexesoAnimalsCheetah from '../../assets/images/pexeso/animals/cheetah.webp'
import PexesoAnimalsCow from '../../assets/images/pexeso/animals/cow.webp'
import PexesoAnimalsDog from '../../assets/images/pexeso/animals/dog.webp'
import PexesoAnimalsDuck from '../../assets/images/pexeso/animals/duck.webp'
import PexesoAnimalsElephant from '../../assets/images/pexeso/animals/elephant.webp'
import PexesoAnimalsFox from '../../assets/images/pexeso/animals/fox.webp'
import PexesoAnimalsFrog from '../../assets/images/pexeso/animals/frog.webp'
import PexesoAnimalsGiraffe from '../../assets/images/pexeso/animals/giraffe.webp'
import PexesoAnimalsHen from '../../assets/images/pexeso/animals/hen.webp'
import PexesoAnimalsHippo from '../../assets/images/pexeso/animals/hippo.webp'
import PexesoAnimalsHorse from '../../assets/images/pexeso/animals/horse.webp'
import PexesoAnimalsKoala from '../../assets/images/pexeso/animals/koala.webp'
import PexesoAnimalsLion from '../../assets/images/pexeso/animals/lion.webp'
import PexesoAnimalsOrangutan from '../../assets/images/pexeso/animals/orangutan.webp'
import PexesoAnimalsPig from '../../assets/images/pexeso/animals/pig.webp'
import PexesoAnimalsRabbit from '../../assets/images/pexeso/animals/rabbit.webp'
import PexesoAnimalsSheep from '../../assets/images/pexeso/animals/sheep.webp'
import PexesoAnimalsZebra from '../../assets/images/pexeso/animals/zebra.webp'
import BlackCard from '../../assets/images/pexeso/black_card_bee.gif'
import BlackCardBackground from '../../assets/images/pexeso/black_card_bg.webp'
import PexesoClothesBelt from '../../assets/images/pexeso/clothes/belt.webp'
import PexesoClothesCap from '../../assets/images/pexeso/clothes/cap.webp'
import PexesoClothesCoat from '../../assets/images/pexeso/clothes/coat.webp'
import PexesoClothesDress from '../../assets/images/pexeso/clothes/dress.webp'
import PexesoClothesGloves from '../../assets/images/pexeso/clothes/gloves.webp'
import PexesoClothesHat from '../../assets/images/pexeso/clothes/hat.webp'
import PexesoClothesHeel from '../../assets/images/pexeso/clothes/heel.webp'
import PexesoClothesJacket from '../../assets/images/pexeso/clothes/jacket.webp'
import PexesoClothesJeans from '../../assets/images/pexeso/clothes/jeans.webp'
import PexesoClothesScarf from '../../assets/images/pexeso/clothes/scarf.webp'
import PexesoClothesShoes from '../../assets/images/pexeso/clothes/shoes.webp'
import PexesoClothesShorts from '../../assets/images/pexeso/clothes/shorts.webp'
import PexesoClothesSkirt from '../../assets/images/pexeso/clothes/skirt.webp'
import PexesoClothesSlippers from '../../assets/images/pexeso/clothes/slippers.webp'
import PexesoClothesSocks from '../../assets/images/pexeso/clothes/socks.webp'
import PexesoClothesSuit from '../../assets/images/pexeso/clothes/suit.webp'
import PexesoClothesSweater from '../../assets/images/pexeso/clothes/sweater.webp'
import PexesoClothesSweatpants from '../../assets/images/pexeso/clothes/sweatpants.webp'
import PexesoClothesTrousers from '../../assets/images/pexeso/clothes/trousers.webp'
import PexesoClothesTshirt from '../../assets/images/pexeso/clothes/tshirt.webp'
import PexesoColorBeige from '../../assets/images/pexeso/colors/beige.webp'
import PexesoColorBlack from '../../assets/images/pexeso/colors/black.webp'
import PexesoColorBlue from '../../assets/images/pexeso/colors/blue.webp'
import PexesoColorBrown from '../../assets/images/pexeso/colors/brown.webp'
import PexesoColorDarkBlue from '../../assets/images/pexeso/colors/dark_blue.webp'
import PexesoColorDarkGreen from '../../assets/images/pexeso/colors/dark_green.webp'
import PexesoColorGold from '../../assets/images/pexeso/colors/gold.webp'
import PexesoColorGray from '../../assets/images/pexeso/colors/gray.webp'
import PexesoColorGreen from '../../assets/images/pexeso/colors/green.webp'
import PexesoColorLightBlue from '../../assets/images/pexeso/colors/light_blue.webp'
import PexesoColorLightGreen from '../../assets/images/pexeso/colors/light_green.webp'
import PexesoColorMagenta from '../../assets/images/pexeso/colors/magenta.webp'
import PexesoColorOrange from '../../assets/images/pexeso/colors/orange.webp'
import PexesoColorPink from '../../assets/images/pexeso/colors/pink.webp'
import PexesoColorPurple from '../../assets/images/pexeso/colors/purple.webp'
import PexesoColorRed from '../../assets/images/pexeso/colors/red.webp'
import PexesoColorSilver from '../../assets/images/pexeso/colors/silver.webp'
import PexesoColorTurquoise from '../../assets/images/pexeso/colors/turquoise.webp'
import PexesoColorWhite from '../../assets/images/pexeso/colors/white.webp'
import PexesoColorYellow from '../../assets/images/pexeso/colors/yellow.webp'
import PexesoFoodApple from '../../assets/images/pexeso/food/apple.webp'
import PexesoFoodBanana from '../../assets/images/pexeso/food/banana.webp'
import PexesoFoodBread from '../../assets/images/pexeso/food/bread.webp'
import PexesoFoodButter from '../../assets/images/pexeso/food/butter.webp'
import PexesoFoodCarrot from '../../assets/images/pexeso/food/carrot.webp'
import PexesoFoodCheese from '../../assets/images/pexeso/food/cheese.webp'
import PexesoFoodChicken from '../../assets/images/pexeso/food/chicken.webp'
import PexesoFoodCucumber from '../../assets/images/pexeso/food/cucumber.webp'
import PexesoFoodDessert from '../../assets/images/pexeso/food/dessert.webp'
import PexesoFoodEgg from '../../assets/images/pexeso/food/egg.webp'
import PexesoFoodFish from '../../assets/images/pexeso/food/fish.webp'
import PexesoFoodIce from '../../assets/images/pexeso/food/ice.webp'
import PexesoFoodMilk from '../../assets/images/pexeso/food/milk.webp'
import PexesoFoodPepper from '../../assets/images/pexeso/food/pepper.webp'
import PexesoFoodPotato from '../../assets/images/pexeso/food/potato.webp'
import PexesoFoodRice from '../../assets/images/pexeso/food/rice.webp'
import PexesoFoodSalad from '../../assets/images/pexeso/food/salad.webp'
import PexesoFoodTomato from '../../assets/images/pexeso/food/tomato.webp'
import PexesoFoodWater from '../../assets/images/pexeso/food/water.webp'
import PexesoFoodWatermelon from '../../assets/images/pexeso/food/watermelon.webp'
import GoldenCard from '../../assets/images/pexeso/golden_card.gif'
import PexesoHobbiesBaking from '../../assets/images/pexeso/hobbies/baking.webp'
import PexesoHobbiesChess from '../../assets/images/pexeso/hobbies/chess.webp'
import PexesoHobbiesClimbing from '../../assets/images/pexeso/hobbies/climbing.webp'
import PexesoHobbiesCooking from '../../assets/images/pexeso/hobbies/cooking.webp'
import PexesoHobbiesCycling from '../../assets/images/pexeso/hobbies/cycling.webp'
import PexesoHobbiesDancing from '../../assets/images/pexeso/hobbies/dancing.webp'
import PexesoHobbiesFootball from '../../assets/images/pexeso/hobbies/football.webp'
import PexesoHobbiesGames from '../../assets/images/pexeso/hobbies/games.webp'
import PexesoHobbiesGardening from '../../assets/images/pexeso/hobbies/gardening.webp'
import PexesoHobbiesGuitar from '../../assets/images/pexeso/hobbies/guitar.webp'
import PexesoHobbiesIceSkating from '../../assets/images/pexeso/hobbies/ice_skating.webp'
import PexesoHobbiesLifting from '../../assets/images/pexeso/hobbies/lifting.webp'
import PexesoHobbiesPainting from '../../assets/images/pexeso/hobbies/painting.webp'
import PexesoHobbiesPiano from '../../assets/images/pexeso/hobbies/piano.webp'
import PexesoHobbiesSinging from '../../assets/images/pexeso/hobbies/singing.webp'
import PexesoHobbiesSkating from '../../assets/images/pexeso/hobbies/skating.webp'
import PexesoHobbiesSurfing from '../../assets/images/pexeso/hobbies/surfing.webp'
import PexesoHobbiesTennis from '../../assets/images/pexeso/hobbies/tennis.webp'
import PexesoHobbiesWalking from '../../assets/images/pexeso/hobbies/walking.webp'
import PexesoHobbiesYoga from '../../assets/images/pexeso/hobbies/yoga.webp'
import PexesoJobsArtist from '../../assets/images/pexeso/jobs/artist.webp'
import PexesoJobsAstronaut from '../../assets/images/pexeso/jobs/astronaut.webp'
import PexesoJobsBuilder from '../../assets/images/pexeso/jobs/builder.webp'
import PexesoJobsChef from '../../assets/images/pexeso/jobs/chef.webp'
import PexesoJobsClown from '../../assets/images/pexeso/jobs/clown.webp'
import PexesoJobsDancer from '../../assets/images/pexeso/jobs/dancer.webp'
import PexesoJobsDoctor from '../../assets/images/pexeso/jobs/doctor.webp'
import PexesoJobsFarmer from '../../assets/images/pexeso/jobs/farmer.webp'
import PexesoJobsFirefighter from '../../assets/images/pexeso/jobs/firefighter.webp'
import PexesoJobsFisherman from '../../assets/images/pexeso/jobs/fisherman.webp'
import PexesoJobsInfluencer from '../../assets/images/pexeso/jobs/influencer.webp'
import PexesoJobsMechanic from '../../assets/images/pexeso/jobs/mechanic.webp'
import PexesoJobsMusician from '../../assets/images/pexeso/jobs/musician.webp'
import PexesoJobsNurse from '../../assets/images/pexeso/jobs/nurse.webp'
import PexesoJobsPhotographer from '../../assets/images/pexeso/jobs/photographer.webp'
import PexesoJobsPilot from '../../assets/images/pexeso/jobs/pilot.webp'
import PexesoJobsPoliceOfficer from '../../assets/images/pexeso/jobs/police_officer.webp'
import PexesoJobsScientist from '../../assets/images/pexeso/jobs/scientist.webp'
import PexesoJobsSinger from '../../assets/images/pexeso/jobs/singer.webp'
import PexesoJobsSuperWoman from '../../assets/images/pexeso/jobs/super_woman.webp'
import PexesoKitchenBlender from '../../assets/images/pexeso/kitchen/blender.webp'
import PexesoKitchenBowl from '../../assets/images/pexeso/kitchen/bowl.webp'
import PexesoKitchenChoppingBoard from '../../assets/images/pexeso/kitchen/chopping_board.webp'
import PexesoKitchenCup from '../../assets/images/pexeso/kitchen/cup.webp'
import PexesoKitchenFork from '../../assets/images/pexeso/kitchen/fork.webp'
import PexesoKitchenFridge from '../../assets/images/pexeso/kitchen/fridge.webp'
import PexesoKitchenGlass from '../../assets/images/pexeso/kitchen/glass.webp'
import PexesoKitchenGrater from '../../assets/images/pexeso/kitchen/grater.webp'
import PexesoKitchenKettle from '../../assets/images/pexeso/kitchen/kettle.webp'
import PexesoKitchenKnife from '../../assets/images/pexeso/kitchen/knife.webp'
import PexesoKitchenMicrowave from '../../assets/images/pexeso/kitchen/microwave.webp'
import PexesoKitchenOven from '../../assets/images/pexeso/kitchen/oven.webp'
import PexesoKitchenPan from '../../assets/images/pexeso/kitchen/pan.webp'
import PexesoKitchenPlate from '../../assets/images/pexeso/kitchen/plate.webp'
import PexesoKitchenPot from '../../assets/images/pexeso/kitchen/pot.webp'
import PexesoKitchenSpatula from '../../assets/images/pexeso/kitchen/spatula.webp'
import PexesoKitchenSponge from '../../assets/images/pexeso/kitchen/sponge.webp'
import PexesoKitchenSpoon from '../../assets/images/pexeso/kitchen/spoon.webp'
import PexesoKitchenToaster from '../../assets/images/pexeso/kitchen/toaster.webp'
import PexesoKitchenWhisk from '../../assets/images/pexeso/kitchen/whisk.webp'
import PexesoBanner from '../../assets/images/pexeso/pexeso_banner.webp'
import PexesoBackground from '../../assets/images/pexeso/pexeso_bg.webp'
import PexesoCardCover from '../../assets/images/pexeso/pexeso_card_cover.webp'
import PexesoSound from '../../assets/images/pexeso/pexeso_sound.webp'
import PexesoStarsBackground from '../../assets/images/pexeso/pexeso_stars.webp'
import PexesoRoomsAttic from '../../assets/images/pexeso/rooms/attic.webp'
import PexesoRoomsBalcony from '../../assets/images/pexeso/rooms/balcony.webp'
import PexesoRoomsBasement from '../../assets/images/pexeso/rooms/basement.webp'
import PexesoRoomsBathroom from '../../assets/images/pexeso/rooms/bathroom.webp'
import PexesoRoomsBedroom from '../../assets/images/pexeso/rooms/bedroom.webp'
import PexesoRoomsChildrenRoom from '../../assets/images/pexeso/rooms/children_room.webp'
import PexesoRoomsDiningRoom from '../../assets/images/pexeso/rooms/dining_room.webp'
import PexesoRoomsGarage from '../../assets/images/pexeso/rooms/garage.webp'
import PexesoRoomsGuestRoom from '../../assets/images/pexeso/rooms/guest_room.webp'
import PexesoRoomsHall from '../../assets/images/pexeso/rooms/hall.webp'
import PexesoRoomsKitchen from '../../assets/images/pexeso/rooms/kitchen.webp'
import PexesoRoomsLaundryRoom from '../../assets/images/pexeso/rooms/laundry_room.webp'
import PexesoRoomsLivingRoom from '../../assets/images/pexeso/rooms/living_room.webp'
import PexesoRoomsOffice from '../../assets/images/pexeso/rooms/office.webp'
import PexesoRoomsPantry from '../../assets/images/pexeso/rooms/pantry.webp'
import PexesoRoomsPlayroom from '../../assets/images/pexeso/rooms/playroom.webp'
import PexesoRoomsStorageRoom from '../../assets/images/pexeso/rooms/storage_room.webp'
import PexesoRoomsStudy from '../../assets/images/pexeso/rooms/study.webp'
import PexesoRoomsTerrace from '../../assets/images/pexeso/rooms/terrace.webp'
import PexesoRoomsToilet from '../../assets/images/pexeso/rooms/toilet.webp'
import PexesoSchoolSuppliesBag from '../../assets/images/pexeso/school_supplies/bag.webp'
import PexesoSchoolSuppliesCalculator from '../../assets/images/pexeso/school_supplies/calculator.webp'
import PexesoSchoolSuppliesChair from '../../assets/images/pexeso/school_supplies/chair.webp'
import PexesoSchoolSuppliesChalks from '../../assets/images/pexeso/school_supplies/chalks.webp'
import PexesoSchoolSuppliesColoredPencils from '../../assets/images/pexeso/school_supplies/colored_pencils.webp'
import PexesoSchoolSuppliesCompass from '../../assets/images/pexeso/school_supplies/compass.webp'
import PexesoSchoolSuppliesCrayon from '../../assets/images/pexeso/school_supplies/crayon.webp'
import PexesoSchoolSuppliesEraser from '../../assets/images/pexeso/school_supplies/eraser.webp'
import PexesoSchoolSuppliesGlue from '../../assets/images/pexeso/school_supplies/glue.webp'
import PexesoSchoolSuppliesHighlighter from '../../assets/images/pexeso/school_supplies/highlighter.webp'
import PexesoSchoolSuppliesNotebook from '../../assets/images/pexeso/school_supplies/notebook.webp'
import PexesoSchoolSuppliesNoticeBoard from '../../assets/images/pexeso/school_supplies/notice_board.webp'
import PexesoSchoolSuppliesPen from '../../assets/images/pexeso/school_supplies/pen.webp'
import PexesoSchoolSuppliesPencil from '../../assets/images/pexeso/school_supplies/pencil.webp'
import PexesoSchoolSuppliesPencilCase from '../../assets/images/pexeso/school_supplies/pencil_case.webp'
import PexesoSchoolSuppliesRuler from '../../assets/images/pexeso/school_supplies/ruler.webp'
import PexesoSchoolSuppliesScissors from '../../assets/images/pexeso/school_supplies/scissors.webp'
import PexesoSchoolSuppliesSharpener from '../../assets/images/pexeso/school_supplies/sharpener.webp'
import PexesoSchoolSuppliesStapler from '../../assets/images/pexeso/school_supplies/stapler.webp'
import PexesoSchoolSuppliesTextbook from '../../assets/images/pexeso/school_supplies/textbook.webp'
import PexesoSportBadminton from '../../assets/images/pexeso/sport/badminton.webp'
import PexesoSportBasketball from '../../assets/images/pexeso/sport/basketball.webp'
import PexesoSportBoxing from '../../assets/images/pexeso/sport/boxing.webp'
import PexesoSportClimbing from '../../assets/images/pexeso/sport/climbing.webp'
import PexesoSportCycling from '../../assets/images/pexeso/sport/cycling.webp'
import PexesoSportFootball from '../../assets/images/pexeso/sport/football.webp'
import PexesoSportGolf from '../../assets/images/pexeso/sport/golf.webp'
import PexesoSportGym from '../../assets/images/pexeso/sport/gym.webp'
import PexesoSportHockey from '../../assets/images/pexeso/sport/hockey.webp'
import PexesoSportHorseRiding from '../../assets/images/pexeso/sport/horse_riding.webp'
import PexesoSportIceSkating from '../../assets/images/pexeso/sport/ice_skating.webp'
import PexesoSportRowing from '../../assets/images/pexeso/sport/rowing.webp'
import PexesoSportRun from '../../assets/images/pexeso/sport/run.webp'
import PexesoSportSkiing from '../../assets/images/pexeso/sport/skiing.webp'
import PexesoSportSurfing from '../../assets/images/pexeso/sport/surfing.webp'
import PexesoSportSwim from '../../assets/images/pexeso/sport/swim.webp'
import PexesoSportTableTennis from '../../assets/images/pexeso/sport/table_tennis.webp'
import PexesoSportTennis from '../../assets/images/pexeso/sport/tennis.webp'
import PexesoSportVolleyball from '../../assets/images/pexeso/sport/volleyball.webp'
import PexesoSportYoga from '../../assets/images/pexeso/sport/yoga.webp'
import PexesoTimeEight from '../../assets/images/pexeso/time/eight.webp'
import PexesoTimeEleven from '../../assets/images/pexeso/time/eleven.webp'
import PexesoTimeFive from '../../assets/images/pexeso/time/five.webp'
import PexesoTimeFivePastSeven from '../../assets/images/pexeso/time/five_past_seven.webp'
import PexesoTimeFiveToFive from '../../assets/images/pexeso/time/five_to_five.webp'
import PexesoTimeFour from '../../assets/images/pexeso/time/four.webp'
import PexesoTimeHalfPastFour from '../../assets/images/pexeso/time/half_past_four.webp'
import PexesoTimeHalfPastOne from '../../assets/images/pexeso/time/half_past_one.webp'
import PexesoTimeNine from '../../assets/images/pexeso/time/nine.webp'
import PexesoTimeOne from '../../assets/images/pexeso/time/one.webp'
import PexesoTimeQuarterPastTwo from '../../assets/images/pexeso/time/quarter_past_two.webp'
import PexesoTimeQuarterToThree from '../../assets/images/pexeso/time/quarter_to_three.webp'
import PexesoTimeSeven from '../../assets/images/pexeso/time/seven.webp'
import PexesoTimeSix from '../../assets/images/pexeso/time/six.webp'
import PexesoTimeTeenPastNine from '../../assets/images/pexeso/time/teen_past_nine.webp'
import PexesoTimeTen from '../../assets/images/pexeso/time/ten.webp'
import PexesoTimeThree from '../../assets/images/pexeso/time/three.webp'
import PexesoTimeTwelve from '../../assets/images/pexeso/time/twelve.webp'
import PexesoTimeTwentyToSix from '../../assets/images/pexeso/time/twenty_to_six.webp'
import PexesoTimeTwo from '../../assets/images/pexeso/time/two.webp'
import PexesoUfoBlue from '../../assets/images/pexeso/ufo_blue.webp'
import PexesoUfoGreen from '../../assets/images/pexeso/ufo_green.webp'
import PexesoUfoPurple from '../../assets/images/pexeso/ufo_purple.webp'
import PexesoUfoRed from '../../assets/images/pexeso/ufo_red.webp'
import PexesoWeatherBreeze from '../../assets/images/pexeso/weather/breeze.webp'
import PexesoWeatherCloud from '../../assets/images/pexeso/weather/cloud.webp'
import PexesoWeatherCold from '../../assets/images/pexeso/weather/cold.webp'
import PexesoWeatherCool from '../../assets/images/pexeso/weather/cool.webp'
import PexesoWeatherDrizzle from '../../assets/images/pexeso/weather/drizzle.webp'
import PexesoWeatherFog from '../../assets/images/pexeso/weather/fog.webp'
import PexesoWeatherFrost from '../../assets/images/pexeso/weather/frost.webp'
import PexesoWeatherHail from '../../assets/images/pexeso/weather/hail.webp'
import PexesoWeatherHot from '../../assets/images/pexeso/weather/hot.webp'
import PexesoWeatherHumidity from '../../assets/images/pexeso/weather/humidity.webp'
import PexesoWeatherLightning from '../../assets/images/pexeso/weather/lightning.webp'
import PexesoWeatherMoon from '../../assets/images/pexeso/weather/moon.webp'
import PexesoWeatherRain from '../../assets/images/pexeso/weather/rain.webp'
import PexesoWeatherRainbow from '../../assets/images/pexeso/weather/rainbow.webp'
import PexesoWeatherSnow from '../../assets/images/pexeso/weather/snow.webp'
import PexesoWeatherStorm from '../../assets/images/pexeso/weather/storm.webp'
import PexesoWeatherSun from '../../assets/images/pexeso/weather/sun.webp'
import PexesoWeatherTemperature from '../../assets/images/pexeso/weather/temperature.webp'
import PexesoWeatherWarm from '../../assets/images/pexeso/weather/warm.webp'
import PexesoWeatherWind from '../../assets/images/pexeso/weather/wind.webp'
import ArrowDown from '../../assets/images/quiz/arrow_drop_down_red.png'
import ArrowUp from '../../assets/images/quiz/arrow_drop_up_green.png'
import BadgeIcon from '../../assets/images/quiz/badge.webp'
import QuizCheckmark from '../../assets/images/quiz/checkmark.webp'
import QuizCheckmarkLines from '../../assets/images/quiz/checkmark_lines.webp'
import QuizConfetti from '../../assets/images/quiz/confetti.webp'
import FirstWinner from '../../assets/images/quiz/first_winner.webp'
import OkFoxino from '../../assets/images/quiz/ok_foxino_trimmed.webp'
import PersonIcon from '../../assets/images/quiz/person.webp'
import QuestionFrameBg from '../../assets/images/quiz/question_frame_bg.webp'
import QuizRedCross from '../../assets/images/quiz/red_cross.webp'
import SamePlace from '../../assets/images/quiz/same_place.png'
import SandClock from '../../assets/images/quiz/sand_clock.webp'
import SecondWinner from '../../assets/images/quiz/second_winner.webp'
import QuizAssignmentSound from '../../assets/images/quiz/sound.webp'
import QuizCircleSymbol from '../../assets/images/quiz/svg/circle_symbol.svg'
import FoxinoQuizGameTitle from '../../assets/images/quiz/svg/foxino_quiz_game_title.svg'
import FoxinoQuizLogo from '../../assets/images/quiz/svg/foxino_quiz_logo.svg'
import QrCodeBg from '../../assets/images/quiz/svg/qr_code_bg.svg'
import QrInputBg from '../../assets/images/quiz/svg/qr_input_bg.svg'
import QuizSquareSymbol from '../../assets/images/quiz/svg/square_symbol.svg'
import QuizStarSymbol from '../../assets/images/quiz/svg/star_symbol.svg'
import QuizTriangleSymbol from '../../assets/images/quiz/svg/triangle_symbol.svg'
import ThirdWinner from '../../assets/images/quiz/third_winner.webp'
import Trophy from '../../assets/images/quiz/trophy.webp'
import Speaker from '../../assets/images/speaker.webp'

export const ImageContent = {
  logo: Logo,
  speaker: Speaker,
  wrongRights: WrongRights,
  buttonHintDisabled: PexesoButtonHintDisabled,
  buttonHintEnabled: PexesoButtonHintEnabled,

  pexeso: {
    background: {
      banner: PexesoBanner,
      pexesoStarsBackground: PexesoStarsBackground,
      planets: PexesoBackground
    },
    BlackCard,
    BlackCardBackground,
    goldenCard: GoldenCard,
    cardCover: PexesoCardCover,
    sound: PexesoSound,
    ufo: {
      blue: PexesoUfoBlue,
      green: PexesoUfoGreen,
      purple: PexesoUfoPurple,
      red: PexesoUfoRed
    },
    colors: {
      beige: PexesoColorBeige,
      black: PexesoColorBlack,
      blue: PexesoColorBlue,
      brown: PexesoColorBrown,
      darkBlue: PexesoColorDarkBlue,
      darkGreen: PexesoColorDarkGreen,
      gold: PexesoColorGold,
      gray: PexesoColorGray,
      green: PexesoColorGreen,
      lightBlue: PexesoColorLightBlue,
      lightGreen: PexesoColorLightGreen,
      magenta: PexesoColorMagenta,
      orange: PexesoColorOrange,
      pink: PexesoColorPink,
      purple: PexesoColorPurple,
      red: PexesoColorRed,
      silver: PexesoColorSilver,
      turquoise: PexesoColorTurquoise,
      white: PexesoColorWhite,
      yellow: PexesoColorYellow
    },
    schoolSupplies: {
      bag: PexesoSchoolSuppliesBag,
      chalks: PexesoSchoolSuppliesChalks,
      calculator: PexesoSchoolSuppliesCalculator,
      coloredPencils: PexesoSchoolSuppliesColoredPencils,
      compass: PexesoSchoolSuppliesCompass,
      crayon: PexesoSchoolSuppliesCrayon,
      chair: PexesoSchoolSuppliesChair,
      eraser: PexesoSchoolSuppliesEraser,
      glue: PexesoSchoolSuppliesGlue,
      highlighter: PexesoSchoolSuppliesHighlighter,
      notebook: PexesoSchoolSuppliesNotebook,
      noticeBoard: PexesoSchoolSuppliesNoticeBoard,
      pen: PexesoSchoolSuppliesPen,
      pencilCase: PexesoSchoolSuppliesPencilCase,
      pencil: PexesoSchoolSuppliesPencil,
      ruler: PexesoSchoolSuppliesRuler,
      scissors: PexesoSchoolSuppliesScissors,
      sharpener: PexesoSchoolSuppliesSharpener,
      stapler: PexesoSchoolSuppliesStapler,
      textbook: PexesoSchoolSuppliesTextbook
    },
    weather: {
      breeze: PexesoWeatherBreeze,
      cloud: PexesoWeatherCloud,
      cold: PexesoWeatherCold,
      cool: PexesoWeatherCool,
      drizzle: PexesoWeatherDrizzle,
      fog: PexesoWeatherFog,
      frost: PexesoWeatherFrost,
      hail: PexesoWeatherHail,
      hot: PexesoWeatherHot,
      humidity: PexesoWeatherHumidity,
      lightning: PexesoWeatherLightning,
      moon: PexesoWeatherMoon,
      rain: PexesoWeatherRain,
      rainbow: PexesoWeatherRainbow,
      snow: PexesoWeatherSnow,
      storm: PexesoWeatherStorm,
      sun: PexesoWeatherSun,
      temperature: PexesoWeatherTemperature,
      warm: PexesoWeatherWarm,
      wind: PexesoWeatherWind
    },
    animals: {
      bear: PexesoAnimalsBear,
      cat: PexesoAnimalsCat,
      cheetah: PexesoAnimalsCheetah,
      cow: PexesoAnimalsCow,
      dog: PexesoAnimalsDog,
      duck: PexesoAnimalsDuck,
      fox: PexesoAnimalsFox,
      frog: PexesoAnimalsFrog,
      giraffe: PexesoAnimalsGiraffe,
      hen: PexesoAnimalsHen,
      hippo: PexesoAnimalsHippo,
      horse: PexesoAnimalsHorse,
      koala: PexesoAnimalsKoala,
      lion: PexesoAnimalsLion,
      orangutan: PexesoAnimalsOrangutan,
      pig: PexesoAnimalsPig,
      rabbit: PexesoAnimalsRabbit,
      elephant: PexesoAnimalsElephant,
      sheep: PexesoAnimalsSheep,
      zebra: PexesoAnimalsZebra
    },
    food: {
      apple: PexesoFoodApple,
      banana: PexesoFoodBanana,
      bread: PexesoFoodBread,
      butter: PexesoFoodButter,
      carrot: PexesoFoodCarrot,
      cheese: PexesoFoodCheese,
      chicken: PexesoFoodChicken,
      dessert: PexesoFoodDessert,
      egg: PexesoFoodEgg,
      fish: PexesoFoodFish,
      ice: PexesoFoodIce,
      milk: PexesoFoodMilk,
      cucumber: PexesoFoodCucumber,
      potato: PexesoFoodPotato,
      rice: PexesoFoodRice,
      salad: PexesoFoodSalad,
      watermelon: PexesoFoodWatermelon,
      pepper: PexesoFoodPepper,
      tomato: PexesoFoodTomato,
      water: PexesoFoodWater
    },
    sport: {
      badminton: PexesoSportBadminton,
      basketball: PexesoSportBasketball,
      boxing: PexesoSportBoxing,
      climbing: PexesoSportClimbing,
      cycling: PexesoSportCycling,
      football: PexesoSportFootball,
      golf: PexesoSportGolf,
      gym: PexesoSportGym,
      hockey: PexesoSportHockey,
      horseRiding: PexesoSportHorseRiding,
      iceSkating: PexesoSportIceSkating,
      rowing: PexesoSportRowing,
      run: PexesoSportRun,
      skiing: PexesoSportSkiing,
      surfing: PexesoSportSurfing,
      swim: PexesoSportSwim,
      tabletTennis: PexesoSportTableTennis,
      tennis: PexesoSportTennis,
      volleyball: PexesoSportVolleyball,
      yoga: PexesoSportYoga
    },
    clothes: {
      belt: PexesoClothesBelt,
      cap: PexesoClothesCap,
      coat: PexesoClothesCoat,
      dress: PexesoClothesDress,
      gloves: PexesoClothesGloves,
      hat: PexesoClothesHat,
      heel: PexesoClothesHeel,
      jacket: PexesoClothesJacket,
      jeans: PexesoClothesJeans,
      scarf: PexesoClothesScarf,
      shoes: PexesoClothesShoes,
      shorts: PexesoClothesShorts,
      skirt: PexesoClothesSkirt,
      slippers: PexesoClothesSlippers,
      socks: PexesoClothesSocks,
      suit: PexesoClothesSuit,
      sweater: PexesoClothesSweater,
      sweatpants: PexesoClothesSweatpants,
      trousers: PexesoClothesTrousers,
      tshirt: PexesoClothesTshirt
    },
    jobs: {
      artist: PexesoJobsArtist,
      astronaut: PexesoJobsAstronaut,
      builder: PexesoJobsBuilder,
      chef: PexesoJobsChef,
      clown: PexesoJobsClown,
      dancer: PexesoJobsDancer,
      doctor: PexesoJobsDoctor,
      musician: PexesoJobsMusician,
      farmer: PexesoJobsFarmer,
      firefighter: PexesoJobsFirefighter,
      fisherman: PexesoJobsFisherman,
      influencer: PexesoJobsInfluencer,
      mechanic: PexesoJobsMechanic,
      nurse: PexesoJobsNurse,
      pilot: PexesoJobsPilot,
      policeOfficer: PexesoJobsPoliceOfficer,
      scientist: PexesoJobsScientist,
      photographer: PexesoJobsPhotographer,
      singer: PexesoJobsSinger,
      superWoman: PexesoJobsSuperWoman
    },
    kitchen: {
      blender: PexesoKitchenBlender,
      bowl: PexesoKitchenBowl,
      choppingBoard: PexesoKitchenChoppingBoard,
      cup: PexesoKitchenCup,
      fork: PexesoKitchenFork,
      fridge: PexesoKitchenFridge,
      glass: PexesoKitchenGlass,
      grater: PexesoKitchenGrater,
      kettle: PexesoKitchenKettle,
      knife: PexesoKitchenKnife,
      microwave: PexesoKitchenMicrowave,
      oven: PexesoKitchenOven,
      pan: PexesoKitchenPan,
      plate: PexesoKitchenPlate,
      pot: PexesoKitchenPot,
      spatula: PexesoKitchenSpatula,
      sponge: PexesoKitchenSponge,
      spoon: PexesoKitchenSpoon,
      toaster: PexesoKitchenToaster,
      whisk: PexesoKitchenWhisk
    },
    rooms: {
      attic: PexesoRoomsAttic,
      balcony: PexesoRoomsBalcony,
      basement: PexesoRoomsBasement,
      bathroom: PexesoRoomsBathroom,
      bedroom: PexesoRoomsBedroom,
      childrenRoom: PexesoRoomsChildrenRoom,
      diningRoom: PexesoRoomsDiningRoom,
      garage: PexesoRoomsGarage,
      guestRoom: PexesoRoomsGuestRoom,
      hall: PexesoRoomsHall,
      kitchen: PexesoRoomsKitchen,
      laundryRoom: PexesoRoomsLaundryRoom,
      livingRoom: PexesoRoomsLivingRoom,
      office: PexesoRoomsOffice,
      pantry: PexesoRoomsPantry,
      playroom: PexesoRoomsPlayroom,
      storageRoom: PexesoRoomsStorageRoom,
      study: PexesoRoomsStudy,
      terrace: PexesoRoomsTerrace,
      toilet: PexesoRoomsToilet
    },
    time: {
      eight: PexesoTimeEight,
      eleven: PexesoTimeEleven,
      fivePastSeven: PexesoTimeFivePastSeven,
      fiveToFive: PexesoTimeFiveToFive,
      five: PexesoTimeFive,
      four: PexesoTimeFour,
      halfPastFour: PexesoTimeHalfPastFour,
      halfPastOne: PexesoTimeHalfPastOne,
      nine: PexesoTimeNine,
      one: PexesoTimeOne,
      quarterPastTwo: PexesoTimeQuarterPastTwo,
      quarterToThree: PexesoTimeQuarterToThree,
      seven: PexesoTimeSeven,
      six: PexesoTimeSix,
      teenPastNine: PexesoTimeTeenPastNine,
      ten: PexesoTimeTen,
      three: PexesoTimeThree,
      twelve: PexesoTimeTwelve,
      twentyToSix: PexesoTimeTwentyToSix,
      two: PexesoTimeTwo
    },
    hobbies: {
      baking: PexesoHobbiesBaking,
      chess: PexesoHobbiesChess,
      climbing: PexesoHobbiesClimbing,
      cooking: PexesoHobbiesCooking,
      cycling: PexesoHobbiesCycling,
      dancing: PexesoHobbiesDancing,
      football: PexesoHobbiesFootball,
      games: PexesoHobbiesGames,
      gardening: PexesoHobbiesGardening,
      guitar: PexesoHobbiesGuitar,
      iceSkating: PexesoHobbiesIceSkating,
      lifting: PexesoHobbiesLifting,
      painting: PexesoHobbiesPainting,
      piano: PexesoHobbiesPiano,
      singing: PexesoHobbiesSinging,
      skating: PexesoHobbiesSkating,
      surfing: PexesoHobbiesSurfing,
      tennis: PexesoHobbiesTennis,
      walking: PexesoHobbiesWalking,
      yoga: PexesoHobbiesYoga
    }
  },

  quiz: {
    svg: {
      qrCodeBg: QrCodeBg,
      qrInputBg: QrInputBg,
      quizGameTitle: FoxinoQuizGameTitle,
      quizLogo: FoxinoQuizLogo,
      sandClock: SandClock,
      square: QuizSquareSymbol,
      circle: QuizCircleSymbol,
      star: QuizStarSymbol,
      triangle: QuizTriangleSymbol,
      hamburger: Hamburger
    },
    arrowUp: ArrowUp,
    arrowDown: ArrowDown,
    samePlace: SamePlace,
    questionFrameBg: QuestionFrameBg,
    lobby: PersonIcon,
    badge: BadgeIcon,
    okFoxino: OkFoxino,
    sound: QuizAssignmentSound,
    trophy: Trophy,
    firstWinner: FirstWinner,
    secondWinner: SecondWinner,
    thirdWinner: ThirdWinner,
    confetti: QuizConfetti,
    checkmark: QuizCheckmark,
    checkmarkLine: QuizCheckmarkLines,
    redCross: QuizRedCross
  },

  flipcards: {
    background: PawPattern,
    logo: FlipcardsLogo,
    hamburger: Hamburger,
    correctAnswerTick: CorrectAnswerTick,
    incorrectAnswer: IncorrectAnswer,
    exclamationMark: ExclamationMark,
    exclamationMarkDisabled: ExclamationMarkDisabled,
    teddyBear: TeddyBear,
    crown: Crown,
    batSteal: BatSteal,
    batSuperSteal: BatSuperSteal,
    hedgeTrade: HedgeTrade,
    leadMouse: LeadMouse,
    treeThread: TreeThread,
    unluckyWolf: UnluckyWolf,
    thanksgiving: Thanksgiving,
    doubleHoney: DoubleHoney,
    rabbit: Rabbit,
    randomCube: RandomCube
  },

  classrooms: {
    subject: {
      ENGLISH: {
        1: PhoneBooth,
        2: SchoolBigBen,
        3: SchoolBus,
        4: SchoolDinosaur
      },
      GERMAN: {
        1: ClassGerman1,
        2: ClassGerman2,
        3: ClassGerman3,
        4: ClassGerman4,
        5: ClassGerman5
      },
      SPANISH: {
        1: ClassSpanish1,
        2: ClassSpanish2,
        3: ClassSpanish3,
        4: ClassSpanish4,
        5: ClassSpanish5
      },
      FRENCH: {
        1: ClassBakery,
        2: ClassEiffelTower,
        3: ClassMonaLisa,
        4: ClassNotreDame,
        5: ClassTourDeFrance
      }
    },
    correctTick: CorrectTick,
    englandCrown: EnglandCrown,
    stars: Stars,
    cornerBackground: CornerBackground,
    headerSwitch: ClassroomHeaderSwitch,
    cards: {
      homeworkCard: HomeworkCard,
      flipcardsCard: FlipcardsCard,
      quizCard: QuizCard,
      selfStudyCard: SelfStudyCard,
      testCard: TestCard,
      activityStar: ActivityStar,
      pexesoCard: PexesoGameCard,
      wheelCard: WheelCard
    },
    activities: {
      connectionCorner: ConnectionCorner,
      flipcardsCorner: FlipcardsCorner,
      homeworkCorner: HomeworkCorner,
      pexesoCorner: PexesoCorner,
      quizCorner: QuizCorner,
      testCorner: TestCorner,
      selfStudyCorner: SelfStudyCorner,
      selfStudyPreview: SelfStudyPreview,
      quizPreview: QuizPreview,
      testPreview: TestPreview,
      pexesoPreview: PexesoPreview,
      homeworkPreview: HomeworkPreview,
      wheelPreview: WheelPreview,
      flipcardsPreview: FlipcardsPreview,
      teamLeaderboardBg: TeamLeaderboardBg,
      firstPlace: FirstPlace,
      secondPlace: SecondPlace,
      thirdPlace: ThirdPlace,
      flashingLights: FlashingLights,
      star: Star,
      icons: {
        testIcon: TestIcon,
        homeworkIcon: HomeworkIcon,
        quizIcon: QuizIcon,
        selfLearning: SelfLearningIcon,
        pexesoIcon: PexesoIcon
      }
    },
    progress: {
      schoolBag: SchoolBag,
      test: Test,
      pencil: PencilIcon,
      books: BooksIcon,
      speaker: SpeakerIcon,
      microphone: MicrophoneIcon
    }
  },

  dictionary: {
    noResult: NoResultDictionary,
    level: {
      A1: EducationLevelA1,
      A2: EducationLevelA2,
      B1: BooksIcon,
      B2: EducationLevelB2,
      C1: EducationLevelC1,
      C2: EducationLevelC2
    }
  }
}
