import { Trans } from 'react-i18next'

import { Body, Button, Heading } from '@app/components'
import { ImageContent } from '@app/config/constants/ImageContent'
import { useTranslation } from '@app/locales'

import { logout } from '../actions/authActions'

type Props = {
  onContinueClick: () => void
}

export const IncorrectRole = ({ onContinueClick }: Props) => {
  const { t } = useTranslation(['common', 'error'])

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex max-w-[500px] flex-col items-center justify-center gap-4 text-center text-neutral-400">
        <img src={ImageContent.wrongRights} width={400} className="-mt-8" />
        <Heading size="l">{t('notSufficientRole')}</Heading>
        <div>
          <Trans
            ns={'error'}
            parent={Body}
            className="text-lg"
            i18nKey="notSufficientRoleDescription"
            components={[<span key={1} className="text-white0" />]}
          />
        </div>
        <div className="flex items-center gap-1">
          <Button mode="error" size="small" onClick={onContinueClick}>
            {t('continue')}
          </Button>
          <Button size="small" onClick={logout}>
            {t('logout')}
          </Button>
        </div>
      </div>
    </div>
  )
}
