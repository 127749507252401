import { cloneElement, forwardRef, InputHTMLAttributes, ReactElement, ReactNode } from 'react'
import classnames from 'classnames'
import { AlertCircle, HelpCircle } from '@untitled-ui/icons-react'

import { Tooltip } from '../Tooltip'
import { IconType } from '..'

type VariantType = 'outlined' | 'simple'

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  iconLeft?: ReactElement<IconType> | ReactElement<any>
  leftPart?: ReactNode
  rightPart?: ReactNode
  disabled?: boolean
  error?: boolean
  label?: JSX.Element | string
  hint?: string
  description?: JSX.Element | string
  errorText?: JSX.Element | string
  inputClassName?: string
  wrapperClassName?: string
  variant?: VariantType
}

const variantToStyle: Record<VariantType, string> = {
  outlined: 'border-[2px] focus-within:border-2 px-[14px]',
  simple: ''
}

const validClasses =
  'bg-primary-900 border-primary-600 placeholder:text-neutral-500 focus-within:border-primary-400 hover:border-primary-400'
const errorClasses = 'bg-primary-900 border-error-500 placeholder:text-neutral-500 focus-within:border-error-500'
const disabledClasses = 'bg-primary-900 text-primary-600 border-primary-600 placeholder:text-primary-600'

/**
 * @group Components
 * @category Component
 */
export const TextInput = forwardRef(
  (
    {
      className,
      inputClassName,
      wrapperClassName,
      disabled,
      iconLeft,
      leftPart,
      rightPart,
      description,
      error,
      errorText,
      hint,
      label,
      type = 'text',
      inputMode = 'text',
      variant = 'outlined',
      ...restProps
    }: TextInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const hasError = !!errorText || error
    const variantStyle = variantToStyle[variant]

    return (
      <div className={classnames('flex flex-col gap-2 text-sm text-neutral-100', className)}>
        {label ? <label className="text-primary-200">{label}</label> : null}
        <div
          className={classnames(
            'flex h-[48px] flex-row items-center gap-1 rounded-lg',
            disabled ? disabledClasses : hasError ? errorClasses : validClasses,
            variantStyle,
            wrapperClassName
          )}
        >
          {leftPart}
          {iconLeft &&
            cloneElement(iconLeft, {
              className: classnames(
                disabled ? 'text-primary-600' : hasError ? 'text-error-500' : 'text-neutral-100',
                iconLeft.props.className
              )
            })}
          <input
            {...restProps}
            ref={ref}
            type={type}
            inputMode={inputMode}
            disabled={disabled}
            className={classnames(
              'w-full flex-1 items-center rounded-md bg-primary-900 focus:outline-none',
              disabled ? 'text-primary-600' : '',
              inputClassName
            )}
          />
          {hasError ? (
            <>
              {hint ? (
                <Tooltip label={hint} side="top" triggerClassName="!w-6" isError>
                  <AlertCircle className="text-error-500" />
                </Tooltip>
              ) : (
                <AlertCircle className="text-error-500" />
              )}
            </>
          ) : hint ? (
            <Tooltip label={hint} side="top" disabled={disabled} triggerClassName="!w-6">
              <HelpCircle className="text-neutral-500" />
            </Tooltip>
          ) : null}
          {rightPart}
        </div>
        {errorText ? (
          <div className="text-sm text-error-500">{errorText}</div>
        ) : description ? (
          <div className="text-sm text-neutral-500">{description}</div>
        ) : null}
      </div>
    )
  }
)

TextInput.displayName = 'TextInput'
