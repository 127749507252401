export class Constants {
  static readonly ONE_DAY_MS: number = 24 * 60 * 60 * 1000
  static readonly ONE_WEEK_MS: number = 7 * Constants.ONE_DAY_MS

  static readonly CONTENT_TYPE_JSON = { 'Content-Type': 'application/json' }

  static readonly API_URL = import.meta.env.VITE_BASE_URL
  static readonly GRAPHQL_QUERY_URL = `${import.meta.env.VITE_BASE_URL}/graphql`
  static readonly GRAPHQL_SUBSCRIPTIONS_URL = `${import.meta.env.VITE_WEBSOCKET_URL}/subscriptions`
  static readonly FILE_SERVICE_ENDPOINT = `${import.meta.env.VITE_BASE_URL}/file`
  static readonly UNITY_SERVER_URL = `${import.meta.env.VITE_UNITY_SERVER_URL}`

  static readonly AUTH_ENDPOINT = import.meta.env.VITE_AUTH_ENDPOINT
  static readonly DEFAULT_TENANT = import.meta.env.VITE_DEFAULT_TENANT
  static readonly KEYCLOAK_CLIENT_ID = import.meta.env.VITE_KEYCLOAK_CLIENT_ID
  static readonly SUPPORT_EMAIL = 'anet@foxino.ai'

  // Speech service
  static readonly SPEECH_REGION = import.meta.env.VITE_SPEECH_REGION
  static readonly SPEECH_KEY = import.meta.env.VITE_SPEECH_KEY

  // App constants
  static readonly MAX_EVENT_TITLE_LENGTH = 30
  static readonly MAX_EVENT_DESCRIPTION_LENGTH = 500

  static INNER_WIDTH = window.innerWidth
  static INNER_HEIGHT = window.innerHeight

  static readonly SUPPORT_URL = 'https://help.foxino.com/cs/'

  static readonly LOGIN_QR_URL = `${import.meta.env.VITE_MOBILE_APP_URL}/qr-login`

  static readonly MAX_RETRIES = 3
}
