import { ButtonHTMLAttributes, ReactElement, SVGProps } from 'react'
import React from 'react'
import classNames from 'classnames'
import { Loading01 } from '@untitled-ui/icons-react'

import { Body } from '..'

import './style.css'

export type IconType = (props: SVGProps<SVGSVGElement>) => JSX.Element
type IconSideType = 'left' | 'right'
type SizeType = 'large' | 'medium' | 'small' | 'xs'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  mode?: ModeType
  icon?: ReactElement<IconType> | ReactElement<any>
  iconSide?: IconSideType
  size?: SizeType
  ref?: React.Ref<HTMLButtonElement>
  disabled?: boolean
  loading?: boolean
  fullWidth?: boolean
}
type ModeType = 'primary' | 'secondary' | 'tertiary' | 'error'

const sizeToLabelStyle: Record<'icon' | 'default', Record<SizeType, React.ComponentProps<'p'>['className']>> = {
  default: {
    xs: 'text-[13px] leading-4 px-3 py-[6px]',
    small: 'text-sm px-3 py-[6px]',
    medium: 'text-sm px-4 py-2',
    large: 'text-base px-[18px] py-[10px]'
  },
  icon: {
    xs: 'text-[13px] leading-4 pl-1 pr-[6px] py-[6px]',
    small: 'text-sm px-2 py-[6px]',
    medium: 'text-sm px-3 py-2',
    large: 'text-base px-[18px] py-[10px]'
  }
}

const sizeToIconStyle: Record<SizeType, React.ComponentProps<'p'>['className']> = {
  xs: 'p-[4px]',
  small: 'p-[6px]',
  medium: 'p-[10px]',
  large: 'p-[12px]'
}

const modeToStyles: Record<ModeType, Record<'default' | 'disabled', React.ComponentProps<'p'>['className']>> = {
  primary: {
    default:
      'text-primary-100 shadow-xs bg-gradient-to-b from-[#5F51D7] to-[#4136C3] border-primary-400 hover:bg-gradient-to-r active:from-[#7164E9] active:to-[#6153D0] border-2',
    disabled: 'border-primary-400 text-primary-300 border-2'
  },
  secondary: {
    default:
      'text-primary-100 shadow-xs bg-primary-1000  border-primary-400 hover:border-primary-300 active:bg-primary-900 border-2',
    disabled: 'text-primary-600 border-primary-400 bg-primary-1000 border-2'
  },
  tertiary: {
    default:
      'text-primary-100 shadow-xs bg-primary-1000 hover:border-primary-300 hover:bg-primary-900 active:bg-primary-800 border-none ',
    disabled: 'text-primary-600 bg-primary-1000'
  },
  error: {
    default:
      'text-error-1000 shadow-xs bg-error-300  border-error-400 hover:border-error-500 active:bg-error-200 active:border-error-300 border-2 hover:bg-error-400',
    disabled: 'text-error-1000 border-error-400 bg-error-300 border-2 opacity-50'
  }
}

/**
 * @group Components
 * @category Props
 */
export const Button = ({
  className,
  children,
  disabled,
  loading,
  fullWidth,
  type = 'button',
  size = 'medium',
  mode = 'primary',
  icon,
  iconSide = 'left',
  ...restProps
}: Props) => {
  const iconOnly = !children && icon
  const sizeStyle = iconOnly ? sizeToIconStyle[size] : sizeToLabelStyle[icon ? 'icon' : 'default'][size]
  const modeStyle = iconOnly ? '' : modeToStyles[mode][disabled ? 'disabled' : 'default']

  return (
    <button
      className={classNames(
        'relative z-10 flex max-h-10 whitespace-nowrap rounded-[10px] bg-gradient-to-br',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        fullWidth ? 'w-full' : 'w-auto'
      )}
      type={type}
      disabled={disabled || loading}
      {...restProps}
    >
      {loading && (
        <div className="absolute left-0 flex h-full w-full items-center justify-center">
          <Loading01 className="spin-animation" />
        </div>
      )}
      <div
        className={classNames(
          'flex h-fit max-h-10 w-full items-center justify-center rounded-[10px]',
          sizeStyle,
          modeStyle,
          className
        )}
      >
        <div
          className={classNames(
            loading && 'opacity-0',
            'flex flex-nowrap items-center justify-center gap-2',
            iconSide === 'left' ? 'flex-row' : 'flex-row-reverse'
          )}
        >
          {icon &&
            React.cloneElement(icon, { className: classNames(icon.props.className, size === 'xs' && 'scale-75') })}
          {typeof children === 'string' ? (
            <Body size="m" weight="semibold" className={classNames(!icon && 'w-full')}>
              {children}
            </Body>
          ) : (
            children
          )}
        </div>
      </div>
    </button>
  )
}
