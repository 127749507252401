import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Pages } from '@app/config/router/PagesEnum'
import { LogUtils } from '@app/utils/LogUtils'

export const PageNotExist = () => {
  const navigate = useNavigate()

  useEffect(() => {
    LogUtils.logError(new Error('404', { cause: window.location.pathname }), 'PageNotExist', 'useEffect')
    navigate(Pages.ROOT)
  }, [])

  return (
    <div className="flex h-[100vh] w-full items-center justify-center">
      <h1 className="text-4xl">404, stranka neexistuje</h1>
    </div>
  )
}
